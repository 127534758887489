import { useEffect, useMemo, useState, useCallback, useRef } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
} from "material-react-table";
import { Reminder } from "../../Models/Reminder";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { CustomDatePicker } from "./CustomDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Button,
  ThemeProvider,
  createTheme,
  Typography,
  darken,
  Tooltip,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import { Delete, Refresh, UnfoldLess } from "@mui/icons-material";
import { SnoozeBy } from "../../Models/SnoozeBy";
import React from "react";

// https://day.js.org/docs/en/plugin/relative-time#docsNav
dayjs.extend(relativeTime);

// https://mui.com/material-ui/customization/dark-mode/
const theme = createTheme({
  palette: {
    mode: "light", // dark
  },
});

interface IProps {
  reminders: Reminder[];
  deleteReminder: (row: MRT_Row<Reminder>) => void;
  snoozeReminder: (row: MRT_Row<Reminder>, snoozeBy: SnoozeBy) => void;
  refreshReminders: () => void;
}

const MaterialTable = (props: IProps) => {
  const [offsetScrollTo] = useState(5);
  const tableInstanceRef = useRef<MRT_TableInstance<Reminder>>(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [isRenderable, setIsRenderable] = useState(false);

  const columns = useMemo<MRT_ColumnDef<Reminder>[]>(
    () => [
      {
        accessorKey: "text",
        header: "Text",
        size: 20,
        sortingFn: "text",
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                wordWrap: "normal",
                width: "800px",
                overflowWrap: "normal",
              }}
            >
              {cell.getValue() as string}
            </div>
          );
        },
      },
      {
        header: "When",
        size: 50,
        Cell: ({ row }) => {
          return dayjs().to(dayjs(row.original.dateTime));
        },
      },
      {
        accessorKey: "dateTime", //normal accessorKey
        header: "On",
        size: 50,
        sortingFn: "datetime",
        Cell: ({ cell }) => {
          // console.log("cell.getValue()", cell.getValue());
          // console.log("cell.getValue() as Date", cell.getValue() as Date);
          return dayjs(cell.getValue() as any as string).format(
            "DD MMM, YYYY @ hh:mm A"
          );
        },

        // Filter: ({ column }) => <CustomDatePicker {...column} />,
        // //Custom Date Picker Filter from @mui/x-date-pickers
        // Filter: ({ column }) => (
        //   <div>
        //     <p>Hello</p>
        //   </div>
        // ),
        //Custom Date Picker Filter from @mui/x-date-pickers

        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                console.log(newValue);
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  sx: { minWidth: "120px" },
                  variant: "standard",
                },
              }}
            />
          </LocalizationProvider>
        ),
        filterFn: (row, id, filterValue) => {
          setTimeout(() => {
            console.log("resetting page index");
            tableInstanceRef.current?.resetPageIndex(true);
          }, 500);
          return (
            dayjs(row.original.dateTime).format("DD MM YYYY") ===
            dayjs(filterValue).format("DD MM YYYY")
          );
        },
      },
      {
        accessorKey: "cronExpression",
        header: "CRON",
        size: 30,
      },
      {
        accessorKey: "reminderId", //access nested data with dot notation
        header: "Reminder ID",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "hangfireJobId",
        header: "Hangfire Job ID",
        enableEditing: false,
        size: 80,
      },
    ],
    []
  );

  const SnoozeMultipleReminders = async (
    table: MRT_TableInstance<Reminder>,
    snoozeBy: SnoozeBy
  ) => {
    table.getSelectedRowModel().rows.forEach(async (row) => {
      await props.snoozeReminder(row, snoozeBy);
    });
  };

  const ScrollToJustUpcomingReminder = () => {
    let rowNo = 0;
    if (props.reminders.length === 0) return;
    while (
      rowNo < props.reminders.length &&
      dayjs(props.reminders[rowNo].dateTime) < dayjs(new Date())
    ) {
      rowNo++;
    }

    // as scrollIntoView will make the reminder come to top of screen, we want it to be offset in center
    rowNo -= offsetScrollTo;

    const rows: HTMLCollection =
      document.getElementsByClassName("MuiTableRow-root");
    rows.item(rowNo)?.scrollIntoView();
  };

  const ShowTodays = () => {
    let todayFilter = {
      id: "dateTime",
      value: dayjs(dayjs(new Date()).format("YYYY-MM-DD")),
    };
    tableInstanceRef.current?.setColumnFilters([todayFilter]);
    // to make reminders show up - they dont show up for filtered reminders incase pagination is on
    // below line does not work without a delay
    setTimeout(() => {
      tableInstanceRef.current?.resetPageIndex(true);
    }, 500);
  };

  const ShowAll = () => {
    tableInstanceRef.current?.setColumnFilters([]);
    setTimeout(() => {
      ScrollToJustUpcomingReminder();
      tableInstanceRef.current?.setPageIndex(pageIndex);
    }, 500);
  };

  const CalcPageIndex = () => {
    let rowNo = 0;
    if (props.reminders.length === 0) return;
    while (
      rowNo < props.reminders.length &&
      dayjs(props.reminders[rowNo].dateTime) < dayjs(new Date())
    ) {
      rowNo++;
    }

    // rowNo = 200;
    let pageIndex = Math.floor(rowNo / pageSize);

    setPageIndex(pageIndex);
    setIsRenderable(true);
  };

  const Test = () => {
    // tableInstanceRef.current?.setPageIndex(0);
    // tableInstanceRef.current?.resetPagination();
    tableInstanceRef.current?.resetPageIndex(true);
  };

  useEffect(() => {
    console.log("useEffect");
    CalcPageIndex();
    // ScrollToJustUpcomingReminder();
  });

  return (
    <div>
      {isRenderable ? (
        <>
          {/* https://mui.com/material-ui/customization/dark-mode/ */}
          <ThemeProvider theme={theme}>
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              // layoutMode="grid"
              columns={columns}
              data={props.reminders}
              // enabling row numbers for easy reading
              enableRowNumbers
              // to get scrollable body, we must implement sticky header & footer
              enableStickyFooter
              enableStickyHeader
              // we dont need fullscreen when we are doing enableStickyHeader & enableStickyFooter - as it will look the same
              enableFullScreenToggle={false}
              // // enable detail panel to see longer text reminders which get cut off
              // //https://www.material-react-table.com/docs/guides/detail-panel
              // renderDetailPanel={({ row }) => (
              //   <Box>
              //     <Typography>{row.original.text}</Typography>
              //   </Box>
              // )}
              // // it should darken alternate rows but its not working - it darkens all rows
              // // still keeping it as it looks good
              // // https://www.material-react-table.com/docs/guides/customize-components#customize-table-styles-source-code
              // // this conflicts with conditional styling below - so disabling it - instead using the darken logic below
              // muiTableBodyProps={{
              //   sx: (theme) => ({
              //     "& tr:nth-of-type(odd)": {
              //       backgroundColor: darken(theme.palette.background.default, 0.1),
              //     },
              //   }),
              // }}
              // conditionally style rows
              // https://www.material-react-table.dev/?path=/story/styling-style-table-body-rows--conditionally-style-mui-table-row
              muiTableBodyRowProps={({ row }) => ({
                hover: true,
                sx: (theme) => ({
                  // ":hover": {
                  //   bgcolor: "blue !important",
                  //   backgroundColor: "blue !important",
                  // },
                  // "&:hover": {
                  //   backgroundColor: "blue !important",
                  // },
                  backgroundColor:
                    dayjs(row.original.dateTime) < dayjs(new Date())
                      ? "#ffabaf"
                      : // : darken(theme.palette.background.default, 0.1),
                        null,
                }),
              })}
              // disabling pagination because date filtering is not working with pagination.
              // if we search for an record which is not in that page, then it will not show up in results
              enablePagination={true}
              // to show delete button for each row
              enableRowActions
              positionActionsColumn="last"
              renderRowActions={({ cell, row, table }) => (
                <>
                  <Box sx={{ display: "block" }}>
                    <Tooltip arrow placement="right" title="Delete">
                      <IconButton
                        color="error"
                        onClick={() => props.deleteReminder(row)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                    <Button
                      onClick={() =>
                        props.snoozeReminder(row, SnoozeBy.MINUTE_20)
                      }
                    >
                      20Mn
                    </Button>
                    <Button
                      onClick={() => props.snoozeReminder(row, SnoozeBy.HOUR_1)}
                    >
                      1Hr
                    </Button>
                    <Button
                      onClick={() => props.snoozeReminder(row, SnoozeBy.HOUR_3)}
                    >
                      3Hr
                    </Button>
                    <Button
                      onClick={() =>
                        props.snoozeReminder(row, SnoozeBy.TOMORROW)
                      }
                    >
                      TMRW
                    </Button>
                    <Button
                      onClick={() => props.snoozeReminder(row, SnoozeBy.MONDAY)}
                    >
                      MNDY
                    </Button>
                  </Box>
                </>
              )}
              // enableColumnResizing
              enableRowSelection
              // enableColumnOrdering
              enableEditing
              editingMode="cell"
              initialState={{
                // https://www.material-react-table.com/docs/guides/density-toggle
                density: "compact",
                pagination: {
                  pageIndex: pageIndex,
                  pageSize: pageSize,
                },

                columnVisibility: {
                  // column name must match accessor key
                  cronExpression: true,
                  reminderId: false,
                  hangfireJobId: false,
                },
              }}
              muiTablePaginationProps={{
                rowsPerPageOptions: [20, 50],
              }}
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                  }}
                >
                  <IconButton
                    sx={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                    }}
                    onClick={() => props.refreshReminders()}
                  >
                    <Refresh />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                    }}
                    onClick={ScrollToJustUpcomingReminder}
                  >
                    <UnfoldLess />
                  </IconButton>
                  <Button variant="contained" onClick={ShowTodays}>
                    Show Today's
                  </Button>
                  <Button variant="contained" onClick={ShowAll}>
                    Show All
                  </Button>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                  >
                    <Button
                      onClick={() => {
                        SnoozeMultipleReminders(table, SnoozeBy.MINUTE_20);
                      }}
                    >
                      20Mn
                    </Button>
                    <Button
                      onClick={() => {
                        SnoozeMultipleReminders(table, SnoozeBy.HOUR_1);
                      }}
                    >
                      1Hr
                    </Button>
                    <Button
                      onClick={() => {
                        SnoozeMultipleReminders(table, SnoozeBy.HOUR_3);
                      }}
                    >
                      3Hr
                    </Button>
                    <Button
                      onClick={() => {
                        SnoozeMultipleReminders(table, SnoozeBy.TOMORROW);
                      }}
                    >
                      TMRW
                    </Button>
                    <Button
                      onClick={() => {
                        SnoozeMultipleReminders(table, SnoozeBy.MONDAY);
                      }}
                    >
                      MNDY
                    </Button>
                  </ButtonGroup>
                  <Button
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    onClick={async () => {
                      // console.log(table.getSelectedRowModel().rows);
                      await table
                        .getSelectedRowModel()
                        .rows.forEach(async (row) => {
                          await props.deleteReminder(row);
                        });
                    }}
                    color="error"
                    variant="outlined"
                  >
                    Delete
                  </Button>

                  <Button variant="contained" onClick={Test} disabled>
                    Test
                  </Button>
                </Box>
              )}
            />
          </ThemeProvider>
        </>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default MaterialTable;
