import axios from "axios";
import { Reminder } from "../../Models/Reminder";
import { SortBy } from "../../Models/SortBy";
import { GetPaginatedRemindersRequest } from "./Models/GetPaginatedReminders/GetPaginatedRemindersRequest";
import { GetPaginatedRemindersResponse } from "./Models/GetPaginatedReminders/GetPaginatedRemindersResponse";
import { GetRemindersRequest } from "./Models/GetReminders/GetRemindersRequest";
import { GetRemindersResponse } from "./Models/GetReminders/GetRemindersResponse";
import { DeleteReminderResponse } from "./Models/DeleteReminder/DeleteReminderResponse";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../AuthHelper/AuthConfig";
import { GetAllRemindersResponse } from "./Models/GetAllReminders/GetAllRemindersResponse";
import { SnoozeBy } from "../../Models/SnoozeBy";
import { SnoozeReminderRequest } from "./Models/SnoozeReminder/SnoozeReminderRequest";
import { SnoozeReminderResponse } from "./Models/SnoozeReminder/SnoozeReminderResponse";

export class APIHelper {
  private baseUrl: string;
  private msalInstance: IPublicClientApplication;

  constructor(baseUrl: string, msalInstance: IPublicClientApplication) {
    this.baseUrl = baseUrl;
    this.msalInstance = msalInstance;
  }

  private async _getAccessToken(): Promise<string> {
    // we can guarentee that user is already authenticated by the time they reach here
    // this is because in App, we are using <MsalAuthenticationTemplate> which forces authentication
    if (this.msalInstance.getAllAccounts().length === 0) {
      throw new Error("User unauthenticated");
    }

    if (this.msalInstance.getActiveAccount() == null)
      this.msalInstance.setActiveAccount(this.msalInstance.getAllAccounts()[0]);
    return (await this.msalInstance.acquireTokenSilent(loginRequest))
      .accessToken;
  }

  private async _getConfig() {
    const accessToken = await this._getAccessToken();
    let headers = {
      Authorization: "Bearer " + accessToken,
    };

    let config = {
      headers: headers,
    };

    return config;
  }

  public async getPaginatedReminders(
    userId: string,
    pageSize: number = 2,
    offset: number = 0,
    sortBy: SortBy = SortBy.DATETIME_ASC
  ): Promise<Reminder[]> {
    let url = this.baseUrl + "/reminder/getPaginatedReminders";
    let body: GetPaginatedRemindersRequest = {
      userId: userId,
      pageSize: pageSize,
      offset: offset,
      sortBy: sortBy,
    };

    let response = await axios.post(url, body);
    let data = response.data as GetPaginatedRemindersResponse;
    return data.reminders;
  }

  public async getReminders(
    userId: string,
    olderCount: number = 1,
    newerCount: number = 1,
    dateTime?: Date
  ): Promise<Reminder[]> {
    let url = this.baseUrl + "/reminder/getReminders";
    let body: GetRemindersRequest = {
      userId: userId,
      olderCount: olderCount,
      newerCount: newerCount,
    };

    if (dateTime != null) body.dateTime = dateTime;

    let response = await axios.post(url, body);
    let data = response.data as GetRemindersResponse;
    return data.reminders;
  }

  public async getAllReminders(userId: string): Promise<Reminder[]> {
    let url = this.baseUrl + "/reminder/getAllReminders/" + userId;
    let response = await axios.get(url, await this._getConfig());
    let data = response.data as GetAllRemindersResponse;
    return data.reminders;
  }

  public async deleteReminder(
    userId: string,
    reminderId: string
  ): Promise<DeleteReminderResponse> {
    let url =
      this.baseUrl + "/reminder/deleteReminder/" + userId + "/" + reminderId;

    let response = await axios.delete(url, await this._getConfig());
    let data = response.data as DeleteReminderResponse;
    return data;
  }

  public async snoozeReminder(
    userId: string,
    reminderId: string,
    reminderSnooze: SnoozeBy
  ): Promise<SnoozeReminderResponse> {
    let url = this.baseUrl + "/reminder/snoozeReminder";

    let body: SnoozeReminderRequest = {
      userId: userId,
      reminderId: reminderId,
      reminderSnooze: reminderSnooze,
    };

    let response = await axios.put(url, body, await this._getConfig());
    let data = response.data as SnoozeReminderResponse;
    return data;
  }
}
