import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import Main from "./Pages/Main/Main";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./Helpers/AuthHelper/AuthConfig";
import { ErrorComponent } from "./Helpers/AuthHelper/ErrorComponent";
import { LoadingComponent } from "./Helpers/AuthHelper/LoadingComponent";

const pca: PublicClientApplication = new PublicClientApplication(msalConfig);

function App() {
  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <Main />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
