import { Configuration, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "0b0fe025-617c-4023-99bd-6bc9386eadb1",
    authority:
      "https://login.microsoftonline.com/149d669a-5154-4491-b59a-ab65e2acd3f8",
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [
    "api://383d4b60-9016-4c52-9ed1-0c3e42308916/Reminder.Read.All",
    "api://383d4b60-9016-4c52-9ed1-0c3e42308916/Reminder.Write.All",
    "api://383d4b60-9016-4c52-9ed1-0c3e42308916/Hangfire.ReadWrite.All",
  ],
};
