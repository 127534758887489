import { APIHelper } from "../../Helpers/APIHelper/APIHelper";
import { useEffect, useState } from "react";
import { Reminder } from "../../Models/Reminder";
import MaterialTable from "../../Components/MaterialTable/MaterialTable";
import { useMsal } from "@azure/msal-react";
import { MRT_Row } from "material-react-table";
import { SnoozeBy } from "../../Models/SnoozeBy";
// import dayjs from "dayjs";
// import weekday from "dayjs/plugin/weekday";

const baseUrl = "https://myreminderbot-api.azurewebsites.net/api";
const userId = "a4f3ab17-ed06-4546-b1a6-6c8e71a6e5c9";

function Main() {
  // -- state
  const { instance } = useMsal();
  const [reminders, setReminders] = useState<Reminder[]>([]);

  // -- deps
  const apiHelper: APIHelper = new APIHelper(baseUrl, instance);

  const GetAllReminders = async () => {
    const reminders = await apiHelper.getAllReminders(userId);
    setReminders(reminders);
  };

  const DeleteReminder = async (row: MRT_Row<Reminder>) => {
    const data = await apiHelper.deleteReminder(
      userId,
      row.original.reminderId
    );

    reminders.splice(row.index, 1);
    setReminders([...reminders]);
  };

  const SnoozeReminder = async (row: MRT_Row<Reminder>, snoozeBy: SnoozeBy) => {
    // no need to manually update reminder based on snoozeBy - we can use the new reminder object returned from API
    // let reminder: Reminder = reminders[row.index];
    // switch (snoozeBy) {
    //   case SnoozeBy.MINUTE_20: {
    //     reminder.dateTime = dayjs(reminder.dateTime).add(20, "m").toDate();
    //     break;
    //   }
    //   case SnoozeBy.HOUR_1: {
    //     reminder.dateTime = dayjs(reminder.dateTime).add(1, "h").toDate();
    //     break;
    //   }
    //   case SnoozeBy.HOUR_3: {
    //     reminder.dateTime = dayjs(reminder.dateTime).add(3, "h").toDate();
    //     break;
    //   }
    //   case SnoozeBy.TOMORROW: {
    //     reminder.dateTime = dayjs(reminder.dateTime)
    //       .add(1, "d")
    //       .set("h", 9)
    //       .set("m", 0)
    //       .set("s", 0)
    //       .toDate();
    //     break;
    //   }
    //   case SnoozeBy.MONDAY: {
    //     reminder.dateTime = dayjs(reminder.dateTime)
    //       .weekday(7)
    //       .set("h", 9)
    //       .set("m", 0)
    //       .set("s", 0)
    //       .toDate();
    //     break;
    //   }
    // }

    const data = await apiHelper.snoozeReminder(
      userId,
      row.original.reminderId,
      snoozeBy
    );

    reminders.splice(row.index, 1);
    // https://stackoverflow.com/a/586189/6818945
    reminders.splice(row.index, 0, data.new_reminder);
    setReminders([...reminders]);
  };

  const ShowAll = () => {
    console.log(
      reminders.find(
        (x) => x.reminderId === "89e4c0d4-be3c-4243-b847-44947ac3aedc"
      )
    );
  };

  useEffect(() => {
    GetAllReminders();
  }, []);

  return (
    <div>
      <MaterialTable
        reminders={reminders}
        deleteReminder={DeleteReminder}
        snoozeReminder={SnoozeReminder}
        refreshReminders={GetAllReminders}
      />
    </div>
  );
}

export default Main;
